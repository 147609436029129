import React, { Suspense, lazy } from "react";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
/* loader component for Suspense*/
import PageLoader from "./components/Common/PageLoader";

import Base from "./components/Layout/Base";
import BasePage from "./components/Layout/BasePage";
import ProtectedRoute from "./Protected.Routes";
// import BaseHorizontal from './components/Layout/BaseHorizontal';
/* Used to render a lazy component with react-router */
const waitFor = Tag => props => <Tag {...props} />;

function retry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}



//STARTER PAGES
const Maintenance = lazy(() => retry(() => import("./components/Pages/Maintenance")));


//DASHBOARD
const Dashboard = lazy(() => retry(() => import("./components/Dashboard/Dashboard")));

//DASHBOARD
const Payments = lazy(() => retry(() => import("./components/Payments/Index")));
const PaymentDetails = lazy(() => retry(() => import("./components/Payments/View")));


//EDUCATIONAL INSTITUTE MANAGEMENT
const EducationalInstitutions = lazy(() => retry(() => import("./components/EducationalInstitutions/EducationalInstitutions")));
const ViewEducationalInstitution = lazy(() => retry(() => import("./components/EducationalInstitutions/ViewEducationalInstitution")));
const EducationalInstitutionTypes = lazy(() => retry(() => import("./components/EducationalInstitutions/EducationalInstitutionTypes")));

//CLASS MANAGEMENT
const Classes = lazy(() => retry(() => import("./components/Classes/Index")));
const ViewClass = lazy(() => retry(() => import("./components/Classes/View")));

//TUTOR BOOKINGS
const TutorBookings = lazy(() => retry(() => import("./components/Bookings/Index")));
const ViewTutorBooking = lazy(() => retry(() => import("./components/Bookings/View")));

//TUTOR MANAGEMENT BOOKINGS
const Tutors = lazy(() => retry(() => import("./components/Tutors/Index")));
const ViewTutor = lazy(() => retry(() => import("./components/Tutors/View")));



//TUTOR QUIZ MANAGEMENT
const TutorQuiz = lazy(() => retry(() => import("./components/TutorQuiz/TutorQuiz")));
const AddTutorQuiz = lazy(() => retry(() => import("./components/TutorQuiz/AddTutorQuiz")));
const EditTutorQuiz = lazy(() => retry(() => import("./components/TutorQuiz/EditTutorQuiz")));
const ViewTutorQuiz = lazy(() => retry(() => import("./components/TutorQuiz/ViewTutorQuiz")));
const AddTutorQuizQuestion = lazy(() => retry(() => import("./components/TutorQuiz/AddTutorQuizQuestion")));
const EditTutorQuizQuestion = lazy(() => retry(() => import("./components/TutorQuiz/EditTutorQuizQuestion")));
const ViewTutorQuizQuestion = lazy(() => retry(() => import("./components/TutorQuiz/ViewTutorQuizQuestion")));
const TutorQuizTypes = lazy(() => retry(() => import("./components/TutorQuiz/TutorQuizTypes")));
const TutorQuizQuestionTypes = lazy(() => retry(() => import("./components/TutorQuiz/TutorQuizQuestionTypes")));


// USER MANAGEMENT
const Login = lazy(() => retry(() => import("./components/User/Login")));
const Logout = lazy(() => retry(() => import("./components/User/Logout")));
const Users = lazy(() => retry(() => import("./components/User/Users")));
const Admins = lazy(() => retry(() => import("./components/User/Admins")));
const Students = lazy(() => retry(() => import("./components/User/Students")));
const ViewStudent = lazy(() => retry(() => import("./components/User/ViewStudent")));
const UserTutors = lazy(() => retry(() => import("./components/User/Tutors")));
const OldStudents = lazy(() => retry(() => import("./components/User/OldStudents")));
const OldTutors = lazy(() => retry(() => import("./components/User/OldTutors")));
const AddAdminUser = lazy(() => retry(() => import("./components/User/AddAdminUser")));
const EditAdminUser = lazy(() => retry(() => import("./components/User/EditAdminUser")));
// const AddUser = lazy(() => retry(() => import("./components/User/AddUser")));
const Roles = lazy(() => retry(() => import("./components/User/Roles")));
const Permissions = lazy(() => retry(() => import("./components/User/Permissions")));
const RolePermissions = lazy(() => retry(() => import("./components/User/RolePermissions")));
const InvestorUsers = lazy(() => retry(() => import("./components/User/InvestorUsers")));
const AddInvestorUser = lazy(() => retry(() => import("./components/User/AddInvestorUser")));


//WEBSITE
const Blog = lazy(() => retry(() => import("./components/Website/Blog")));
const Subscribers = lazy(() => retry(() => import("./components/Website/Subscribers")));
const WebClients = lazy(() => retry(() => import("./components/Website/WebClients")));
const WebCareers = lazy(() => retry(() => import("./components/WebCareer/WebCareers")));
const AddWebCareer = lazy(() => retry(() => import("./components/WebCareer/AddWebCareer")));
const ViewWebCareer = lazy(() => retry(() => import("./components/WebCareer/ViewWebCareer")));
const EditWebCareer = lazy(() => retry(() => import("./components/WebCareer/EditWebCareer")));
const AddWebCareerRole = lazy(() => retry(() => import("./components/WebCareer/AddWebCareerRole")));
const EditWebCareerRole = lazy(() => retry(() => import("./components/WebCareer/EditWebCareerRole")));
const WebCareerInterviewee = lazy(() => retry(() => import("./components/WebCareer/WebCareerInterviewee")));
const ViewWebCareerInterviewee = lazy(() => retry(() => import("./components/WebCareer/ViewWebCareerInterviewee")));




//SETTINGS
const Grades = lazy(() => retry(() => import("./components/Settings/Grades")));
const Topics = lazy(() => retry(() => import("./components/Settings/Topics")));
const Avatars = lazy(() => retry(() => import("./components/Settings/Avatars")));
const Subjects = lazy(() => retry(() => import("./components/Settings/Subjects")));
const Packages = lazy(() => retry(() => import("./components/Settings/Packages")));
const EducationLevels  = lazy(() => retry(() => import("./components/Settings/EducationLevels")));
const Curriculums  = lazy(() => retry(() => import("./components/Settings/Curriculums")));
const AvailableCountries  = lazy(() => retry(() => import("./components/Settings/AvailableCountries")));


//APP RELEASES
const AppReleases = lazy(() => retry(() => import("./components/AppRelease/Index")));
const AddAppRelease = lazy(() => retry(() => import("./components/AppRelease/Create")));
const EditAppRelease = lazy(() => retry(() => import("./components/AppRelease/Edit")));
const ViewAppRelease = lazy(() => retry(() => import("./components/AppRelease/View")));



//NOTIFICATIONS
const SatuTest = lazy(() => retry(() => import("./components/Notifications/SatuTest")));
const Tips = lazy(() => retry(() => import("./components/Notifications/Tips")));
const BulkSms = lazy(() => retry(() => import("./components/Notifications/BulkSms")));
const BulkUserNotification = lazy(() => retry(() => import("./components/Notifications/BulkUserNotification")));
const SystemNotifications = lazy(() => retry(() => import("./components/SystemNotification/Index")));
const AddSystemNotification = lazy(() => retry(() => import("./components/SystemNotification/Create")));
const EditSystemNotification = lazy(() => retry(() => import("./components/SystemNotification/Edit")));
// const SystemNotifications = lazy(() => retry(() => import("./components/SystemNotification/Index")));


//INVESTORS
const Investors = lazy(() => retry(() => import("./components/Investors/Investors")));
const ViewInvestor = lazy(() => retry(() => import("./components/Investors/ViewInvestor")));
const AddInvestor = lazy(() => retry(() => import("./components/Investors/AddInvestor")));
const OfflinePayments = lazy(() => retry(() => import("./components/Investors/OfflinePayments")));
const AppDownloadStatistics = lazy(() => retry(() => import("./components/Investors/AppDownloadStatistics")));



//Dashboard Data Cooked V1
const DashboardDataCookedV1 = lazy(() => retry(() => import("./components/DashboardDataCookedV1/Index")));
const CreateDashboardDataCookedV1 = lazy(() => retry(() => import("./components/DashboardDataCookedV1/Create")));
const ViewDashboardDataCookedV1 = lazy(() => retry(() => import("./components/DashboardDataCookedV1/View")));
const EditDashboardDataCookedV1 = lazy(() => retry(() => import("./components/DashboardDataCookedV1/Edit")));


//PROMO CODES
const Campaigns = lazy(() => retry(() => import("./components/PromoCode/Campaigns")));
// const CreateDashboardDataCookedV1 = lazy(() => retry(() => import("./components/DashboardDataCookedV1/Create")));


const listofPages = [
  /* See full project for reference */
  '/',
  '/maintenance'
];

const Routes = ({ location }) => {
  const currentKey = location.pathname.split("/")[1] || "/";
  const timeout = { enter: 500, exit: 500 };

  // Animations supported
  //      'rag-fadeIn'
  //      'rag-fadeInRight'
  //      'rag-fadeInLeft'

  const animationName = "rag-fadeIn";

  if (listofPages.indexOf(location.pathname) > -1) {
    return (
      <BasePage>
        <Suspense fallback={<PageLoader />}>
          <Switch location={location}>
            <Route exact path="/" component={waitFor(Login)} />
            <Route exact path="/maintenance" component={waitFor(Maintenance)} />
          </Switch>
        </Suspense>
      </BasePage>
    );
  } else {
    return (
      <Base>
        <TransitionGroup>
          <CSSTransition
            key={currentKey}
            timeout={timeout}
            classNames={animationName}
            exit={false}
          >
            <div>
              <Suspense fallback={<PageLoader />}>
                <Switch location={location}>


                  <ProtectedRoute path="/dashboard" component={waitFor(Dashboard)} />

                  
                  {/** PAYMENTS **/}
                  <ProtectedRoute path="/payment-management/payments" component={waitFor(Payments)} />
                  <ProtectedRoute path="/payment-management/payment/view/:uuid" component={waitFor(PaymentDetails)} />

                  
                  {/** EDUCATIONAL INSTITUTION **/}
                  <ProtectedRoute path="/educational-institution-management/educational-institutions" component={waitFor(EducationalInstitutions)} />
                  <ProtectedRoute path="/educational-institution-management/educational-institution/view/:uuid" component={waitFor(ViewEducationalInstitution)} />
                  <ProtectedRoute path="/educational-institution-management/educational-institution-types" component={waitFor(EducationalInstitutionTypes)} />


                  {/** CLASSES **/}
                  <ProtectedRoute path="/class-management/classes" component={waitFor(Classes)} />
                  <ProtectedRoute path="/class-management/class/view/:uuid" component={waitFor(ViewClass)} />
                  

                  {/** BOOKINGS **/}
                  <ProtectedRoute path="/class-management/tutor-bookings" component={waitFor(TutorBookings)} />
                  <ProtectedRoute path="/class-management/tutor-booking/view/:uuid" component={waitFor(ViewTutorBooking)} />


                  

                  {/** TUTORS MANAGEMENT **/}
                  <ProtectedRoute path="/tutor-management/tutors" component={waitFor(Tutors)} />
                  <ProtectedRoute path="/tutor-management/tutor/view/:uuid" component={waitFor(ViewTutor)} />


                  {/** TUTOR QUIZ **/}
                  <ProtectedRoute path="/tutor-quiz-management/quizzes" component={waitFor(TutorQuiz)} />
                  <ProtectedRoute path="/tutor-quiz-management/quiz/add" component={waitFor(AddTutorQuiz)} />
                  <ProtectedRoute path="/tutor-quiz-management/quiz/edit/:uuid" component={waitFor(EditTutorQuiz)} />
                  <ProtectedRoute path="/tutor-quiz-management/quiz/view/:uuid" component={waitFor(ViewTutorQuiz)} />
                  <ProtectedRoute path="/tutor-quiz-management/quiz/:uuid/add-question/" component={waitFor(AddTutorQuizQuestion)} />
                  <ProtectedRoute path="/tutor-quiz-management/quiz/:uuid/edit-question/:questionUuid" component={waitFor(EditTutorQuizQuestion)} />
                  <ProtectedRoute path="/tutor-quiz-management/quiz/:uuid/view-question/:questionUuid" component={waitFor(ViewTutorQuizQuestion)} />
                  <ProtectedRoute path="/tutor-quiz-management/quiz-types" component={waitFor(TutorQuizTypes)} />
                  <ProtectedRoute path="/tutor-quiz-management/quiz-question-types" component={waitFor(TutorQuizQuestionTypes)} />



                  {/** WEBSITE **/}
                  <ProtectedRoute path="/website-management/blog" component={waitFor(Blog)} />
                  <ProtectedRoute path="/website-management/subscribers" component={waitFor(Subscribers)} />
                  <ProtectedRoute path="/website-management/clients" component={waitFor(WebClients)} />
                  <ProtectedRoute path="/website-management/careers" component={waitFor(WebCareers)} />
                  <ProtectedRoute path="/website-management/career/add" component={waitFor(AddWebCareer)} />
                  <ProtectedRoute path="/website-management/career/view/:uuid" component={waitFor(ViewWebCareer)} />
                  <ProtectedRoute path="/website-management/career/edit/:uuid" component={waitFor(EditWebCareer)} />
                  <ProtectedRoute path="/website-management/career/:uuid/roles/add" component={waitFor(AddWebCareerRole)} />
                  <ProtectedRoute path="/website-management/career/:uuid/roles/edit/:roleUuid" component={waitFor(EditWebCareerRole)} />
                  <ProtectedRoute path="/website-management/career/:uuid/applicants" component={waitFor(WebCareerInterviewee)} />
                  <ProtectedRoute path="/website-management/career/:uuid/applicant/:intervieweeUuid" component={waitFor(ViewWebCareerInterviewee)} />

                  {/* USERS */}
                  <ProtectedRoute path="/user-management/users" component={waitFor(Users)} />
                  <ProtectedRoute path="/user-management/admins" component={waitFor(Admins)} />
                  <ProtectedRoute path="/user-management/students" component={waitFor(Students)} />
                  <ProtectedRoute path="/user-management/student/view/:uuid" component={waitFor(ViewStudent)} />
                  <ProtectedRoute path="/user-management/tutors" component={waitFor(UserTutors)} />
                  <ProtectedRoute path="/user-management/old-students" component={waitFor(OldStudents)} />
                  <ProtectedRoute path="/user-management/old-tutors" component={waitFor(OldTutors)} />
                  <ProtectedRoute path="/user-management/user/add" component={waitFor(AddAdminUser)} />
                  <ProtectedRoute path="/user-management/user/edit/:uuid" component={waitFor(EditAdminUser)} />
                  <ProtectedRoute path="/user-management/roles" component={waitFor(Roles)} />
                  <ProtectedRoute path="/user-management/permissions" component={waitFor(Permissions)} />
                  <ProtectedRoute path="/user-management/role-permissions" component={waitFor(RolePermissions)} />
                  <ProtectedRoute path="/user-management/investor-users" component={waitFor(InvestorUsers)} />
                  <ProtectedRoute path="/user-management/add-investor-user" component={waitFor(AddInvestorUser)} />


                  

                  {/** APP RELEASE **/}
                  <ProtectedRoute path="/app-management/app-releases" component={waitFor(AppReleases)} />
                  <ProtectedRoute path="/app-management/app-release/add" component={waitFor(AddAppRelease)} />
                  <ProtectedRoute path="/app-management/app-release/edit/:uuid" component={waitFor(EditAppRelease)} />
                  <ProtectedRoute path="/app-management/app-release/view/:uuid" component={waitFor(ViewAppRelease)} />
                  <ProtectedRoute path="/app-management/packages" component={waitFor(Packages)} />
                  <ProtectedRoute path="/app-management/available-countries" component={waitFor(AvailableCountries)} />


                  {/** SETTINGS **/}
                  <ProtectedRoute path="/settings/grades" component={waitFor(Grades)} />
                  <ProtectedRoute path="/settings/topics" component={waitFor(Topics)} />
                  <ProtectedRoute path="/settings/avatars" component={waitFor(Avatars)} />
                  <ProtectedRoute path="/settings/subjects" component={waitFor(Subjects)} />
                  <ProtectedRoute path="/settings/education-levels" component={waitFor(EducationLevels)} />
                  <ProtectedRoute path="/settings/curriculums" component={waitFor(Curriculums)} />
                  

                  {/** NOTIFICATIONS **/}
                  <ProtectedRoute path="/satu-test" component={waitFor(SatuTest)} />
                  <ProtectedRoute path="/notification-management/tips" component={waitFor(Tips)} />
                  <ProtectedRoute path="/notification-management/bulk-sms" component={waitFor(BulkSms)} />
                  <ProtectedRoute path="/notification-management/bulk-user" component={waitFor(BulkUserNotification)} />
                  <ProtectedRoute path="/notification-management/system-notifications" component={waitFor(SystemNotifications)} />
                  <ProtectedRoute path="/notification-management/system-notification/add" component={waitFor(AddSystemNotification)} />
                  <ProtectedRoute path="/notification-management/system-notification/edit/:uuid" component={waitFor(EditSystemNotification)} />


                  {/** INVESTORS */}
                  <ProtectedRoute path="/investor-management/investors" component={waitFor(Investors)} />
                  <ProtectedRoute path="/investor-management/investor/view/:uuid" component={waitFor(ViewInvestor)} />
                  <ProtectedRoute path="/investor-management/investor/add" component={waitFor(AddInvestor)} />
                  <ProtectedRoute path="/investor-management/offline-payments" component={waitFor(OfflinePayments)} />
                  <ProtectedRoute path="/investor-management/app-download-statistics" component={waitFor(AppDownloadStatistics)} />


                  {/** INVESTORS */}
                  <ProtectedRoute path="/dashboard-management/v1-datas" component={waitFor(DashboardDataCookedV1)} />
                  <ProtectedRoute path="/dashboard-management/v1-data/add" component={waitFor(CreateDashboardDataCookedV1)} />
                  <ProtectedRoute path="/dashboard-management/v1-data/view/:uuid" component={waitFor(ViewDashboardDataCookedV1)} />
                  <ProtectedRoute path="/dashboard-management/v1-data/edit/:uuid" component={waitFor(EditDashboardDataCookedV1)} />


                  {/** PROMO CODES */}
                  <ProtectedRoute path="/promo-code-management/campaigns" component={waitFor(Campaigns)} />
                  
                  <ProtectedRoute path="/logout" component={waitFor(Logout)} />

                  <Redirect to="/logout" />
                  
                </Switch>
              </Suspense>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Base>
    );
  }
};



export default withRouter(Routes);
