import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces, Trans } from 'react-i18next';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { Collapse, Badge } from 'reactstrap';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions/actions';

import SidebarRun from './Sidebar.run';
import SidebarUserBlock from './SidebarUserBlock';
import { reactLocalStorage } from "reactjs-localstorage";

// import Menu from '../../Menu.js';

/** Component to display headings on sidebar */
const SidebarItemHeader = ({item}) => (
    <li className="nav-heading">
        <span><Trans i18nKey={item.translate}>{item.heading}</Trans></span>
    </li>
)

/** Normal items for the sidebar */
const SidebarItem = ({item, isActive}) => (
    <li className={ isActive ? 'active' : '' }>
        <Link to={item.path} title={item.name}>
            {item.label && <Badge tag="div" className="float-right" color={item.label.color}>{item.label.value}</Badge>}
            {item.icon && <em className={item.icon}></em>}
            <span><Trans i18nKey={item.translate}>{item.name}</Trans></span>
        </Link>
    </li>
)

/** Build a sub menu with items inside and attach collapse behavior */
const SidebarSubItem = ({item, isActive, handler, children, isOpen}) => (
    <li className={ isActive ? 'active' : '' }>
        <div className="nav-item" onClick={ handler }>
            {item.label && <Badge tag="div" className="float-right" color={item.label.color}>{item.label.value}</Badge>}
            {item.icon && <em className={item.icon}></em>}
            <span><Trans i18nKey={item.translate}>{item.name}</Trans></span>
        </div>
        <Collapse isOpen={ isOpen }>
            <ul id={item.path} className="sidebar-nav sidebar-subnav">
                { children }
            </ul>
        </Collapse>
    </li>
)

/** Component used to display a header on menu when using collapsed/hover mode */
const SidebarSubHeader = ({item}) => (
    <li className="sidebar-subnav-header">{item.name}</li>
)

// let Menu = []

class Sidebar extends Component {

    state = {
        collapse: {},
        redirect: false,
        Menu: [],
    }

    componentDidMount() {
        // pass navigator to access router api
        SidebarRun(this.navigator, this.closeSidebar);
        // prepare the flags to handle menu collapsed states
        this.buildCollapseList()

        // Listen for routes changes in order to hide the sidebar on mobile
        this.props.history.listen(this.closeSidebar);

        this.getUserSidebarMenus()

    }


    getUserSidebarMenus = () => {

        let user_session = reactLocalStorage.getObject('user_session');

        this.state.Menu.push(
            {
                heading: "Main Navigation",
                translate: "sidebar.heading.HEADER"
            },
            {
                name: "DASHBOARD",
                icon: "icon-speedometer",
                translate: "sidebar.nav.MENU",
                path: "/dashboard",
            },
            {
                name: "SATU",
                icon: "icon-speedometer",
                translate: "sidebar.nav.MENU",
                path: "/satu-test",
            },
        );
 
        if(user_session !== null && user_session.user !== null && user_session.user) {

            if(user_session.user.role && user_session.user.role.permissions) {
                // let role = user_session.user.role;
                // let permissions = user_session.role.permissions.map(perm => perm.displayName);
                // console.log(user_session.length)
                let modules = user_session.user.role.permissions !== null ? user_session.user.role.permissions.map(perm => perm.module.toLowerCase()) : [];

                if(modules.includes('payments')) {
                    this.state.Menu.push(
                        {
                        name: "PAYMENTS",
                        icon: "icon-graph",
                        translate: "sidebar.nav.MENU",
                        path: "/payment-management/payments",
                        },
                    )
                }

                if(modules.includes('institution management')) {
                    this.state.Menu.push(
                        {
                            name: "INSTITUTE MANAGEMENT",
                            icon: "icon-graduation",
                            translate: "sidebar.nav.MENU",
                            submenu: [
                                {
                                name: "Institutions",
                                translate: "sidebar.nav.SUBMENU",
                                path: "/educational-institution-management/educational-institutions"
                                },
                                {
                                name: "Institute Types",
                                translate: "sidebar.nav.SUBMENU",
                                path: "/educational-institution-management/educational-institution-types"
                                },
                            ]
                        },
                    )
                }

                if(modules.includes('class management')) {
                    this.state.Menu.push(
                        {
                            name: "CLASS MANAGEMENT",
                            icon: "icon-book-open",
                            translate: "sidebar.nav.MENU",
                            submenu: [
                                {
                                name: "Classes",
                                translate: "sidebar.nav.SUBMENU",
                                path: "/class-management/classes"
                                },
                                {
                                name: "Bookings",
                                translate: "sidebar.nav.SUBMENU",
                                path: "/class-management/tutor-bookings"
                                },
                            ]
                        },
                    )
                }

                if(modules.includes('tutor management') || modules.includes('verify tutor')) {
                    this.state.Menu.push(
                        {
                            name: "TUTOR MANAGEMENT",
                            icon: "icon-badge",
                            translate: "sidebar.nav.MENU",
                            submenu: [
                                {
                                name: "Tutor",
                                translate: "sidebar.nav.SUBMENU",
                                path: "/tutor-management/tutors"
                                },
                            ]
                        },
                    )
                }

                if(modules.includes('tutor quiz management')) {
                    this.state.Menu.push(
                        {
                            name: "TUTOR QUIZ MANAGEMENT",
                            icon: "icon-calculator",
                            translate: "sidebar.nav.MENU",
                            submenu: [
                                {
                                name: "Quiz",
                                translate: "sidebar.nav.SUBMENU",
                                path: "/tutor-quiz-management/quizzes"
                                },
                                {
                                name: "Quiz Type",
                                translate: "sidebar.nav.SUBMENU",
                                path: "/tutor-quiz-management/quiz-types"
                                },
                                {
                                name: "Quiz Question Type",
                                translate: "sidebar.nav.SUBMENU",
                                path: "/tutor-quiz-management/quiz-question-types"
                                },
                            ]
                        },
                    )
                }
                
                if(modules.includes('website management')) {
                    let websiteSubmenu = []

                    if(modules.includes('blog')) {
                        websiteSubmenu.push(
                            {
                                name: "Blog",
                                translate: "sidebar.nav.SUBMENU",
                                path: "/website-management/blog"
                            },
                        )
                    }

                    if(modules.includes('careers')) {
                        websiteSubmenu.push(
                            {
                                name: "Careers",
                                translate: "sidebar.nav.SUBMENU",
                                path: "/website-management/careers"
                            },
                        )
                    }

                    if(modules.includes('client-new')) {
                        websiteSubmenu.push(
                            {
                                name: "Clients (New)*",
                                translate: "sidebar.nav.SUBMENU",
                                path: "/website-management/clients"
                            },
                        )
                    }

                    if(modules.includes('subscribers-old')) {
                        websiteSubmenu.push(
                            {
                                name: "Subscribers (Old)",
                                translate: "sidebar.nav.SUBMENU",
                                path: "/website-management/subscribers"
                            },
                        )
                    }

                    this.state.Menu.push(
                        {
                            name: "WEBSITE MANAGEMENT",
                            icon: "icon-globe",
                            translate: "sidebar.nav.MENU",
                            submenu: websiteSubmenu
                        },
                    )
                }

                if(modules.includes('user management')) {

                    let userSubmenu = []

                    if(modules.includes('admins')) {
                        userSubmenu.push(
                            {
                                name: "Admins",
                                translate: "sidebar.nav.SUBMENU",
                                path: "/user-management/admins"
                            },
                        )
                    }

                    if(modules.includes('students')) {
                        userSubmenu.push(
                            {
                                name: "Students",
                                translate: "sidebar.nav.SUBMENU",
                                path: "/user-management/students"
                            },
                        )
                    }

                    if(modules.includes('tutors')) {
                        userSubmenu.push(
                            {
                                name: "Tutors",
                                translate: "sidebar.nav.SUBMENU",
                                path: "/user-management/tutors"
                            },
                        )
                    }

                    if(modules.includes('roles')) {
                        userSubmenu.push(
                            {
                                name: "Roles",
                                translate: "sidebar.nav.SUBMENU",
                                path: "/user-management/roles"
                            }
                        )
                    }

                    if(modules.includes('permissions')) {
                        userSubmenu.push(
                            {
                                name: "Permissions",
                                translate: "sidebar.nav.SUBMENU",
                                path: "/user-management/permissions"
                            },
                        )
                    }

                    if(modules.includes('role permissions')) {
                        userSubmenu.push(
                            {
                                name: "Role Permission",
                                translate: "sidebar.nav.SUBMENU",
                                path: "/user-management/role-permissions"
                            },
                        )
                    }

                    if(modules.includes('investors')) {
                        userSubmenu.push(
                            {
                                name: "Investor Users",
                                translate: "sidebar.nav.SUBMENU",
                                path: "/user-management/investor-users"
                            },
                        )
                    }

                    if(modules.includes('smartclass tutors')) {
                        userSubmenu.push(
                            {
                                name: "Smartclass Tutors",
                                translate: "sidebar.nav.SUBMENU",
                                path: "/user-management/old-tutors"
                            },
                        )
                    }

                    if(modules.includes('smartclass students')) {
                        userSubmenu.push(
                            {
                                name: "Smartclass Students",
                                translate: "sidebar.nav.SUBMENU",
                                path: "/user-management/old-students"
                            },
                        )
                    }

                    this.state.Menu.push(
                        {
                        name: "USERS MANAGEMENT",
                        icon: "icon-people",
                        translate: "sidebar.nav.MENU",
                        submenu: userSubmenu
                        },
                    )

                }

                if(modules.includes('app management')) {
                    let appSubmenu = []

                    if(modules.includes('packages')) {
                        appSubmenu.push(
                            {
                                name: "Packages",
                                translate: "sidebar.nav.SUBMENU",
                                path: "/app-management/packages"
                            },
                        )
                    }

                    if(modules.includes('app releases')) {
                        appSubmenu.push(
                            {
                                name: "App Releases",
                                translate: "sidebar.nav.SUBMENU",
                                path: "/app-management/app-releases"
                            },
                        )
                    }

                    if(modules.includes('available countries')) {
                        appSubmenu.push(
                            {
                                name: "Available Countries",
                                translate: "sidebar.nav.SUBMENU",
                                path: "/app-management/available-countries"
                            },
                        )
                    }

                    this.state.Menu.push(
                        {
                        name: "APP MANAGEMENT",
                        icon: "icon-settings",
                        translate: "sidebar.nav.MENU",
                        submenu: appSubmenu
                        },
                    )
                }

                if(modules.includes('investor management')) {
                    this.state.Menu.push(
                        {
                            name: "INVESTORS MANAGEMENT",
                            icon: "icon-settings",
                            translate: "sidebar.nav.MENU",
                            submenu: [
                                {
                                    name: "Investors",
                                    translate: "sidebar.nav.SUBMENU",
                                    path: "/investor-management/investors"
                                },
                                {
                                    name: "Offline Payments",
                                    translate: "sidebar.nav.SUBMENU",
                                    path: "/investor-management/offline-payments"
                                },
                                {
                                    name: "App Download Statistics",
                                    translate: "sidebar.nav.SUBMENU",
                                    path: "/investor-management/app-download-statistics"
                                },
                                {
                                    name: "Partners",
                                    translate: "sidebar.nav.SUBMENU",
                                    path: "/investor-management/investor/view/:uuid"
                                },
                            ]
                        },
                    )
                }

                if(modules.includes('notification management')) {
                    this.state.Menu.push(
                        {
                            name: "NOTIFICATIONS",
                            icon: "icon-bell",
                            translate: "sidebar.nav.MENU",
                            submenu: [
                                {
                                    name: "Tips",
                                    translate: "sidebar.nav.SUBMENU",
                                    path: "/notification-management/tips"
                                },
                                {
                                    name: "Silabu User",
                                    translate: "sidebar.nav.SUBMENU",
                                    path: "/notification-management/bulk-user"
                                },
                                {
                                    name: "Specific SMS",
                                    translate: "sidebar.nav.SUBMENU",
                                    path: "/notification-management/bulk-sms"
                                },
                                {
                                    name: "System Notifications",
                                    translate: "sidebar.nav.SUBMENU",
                                    path: "/notification-management/system-notifications"
                                },
                            ]
                        },
                    )
                }


                if(modules.includes('promo-code management')) {
                    this.state.Menu.push(
                        {
                            name: "PROMO-CODE MANAGEMENT",
                            icon: "icon-graduation",
                            translate: "sidebar.nav.MENU",
                            submenu: [
                                {
                                    name: "Campaigns",
                                    translate: "sidebar.nav.SUBMENU",
                                    path: "/promo-code-management/campaigns"
                                },
                                // {
                                //     name: "Institute Types",
                                //     translate: "sidebar.nav.SUBMENU",
                                //     path: "/educational-institution-management/educational-institution-types"
                                // },
                            ]
                        },
                    )
                }

                if(modules.includes('settings management')) {
                    this.state.Menu.push(
                        {
                            name: "SETTINGS",
                            icon: "icon-settings",
                            translate: "sidebar.nav.MENU",
                            submenu: [
                                {
                                    name: "Curriculums",
                                    translate: "sidebar.nav.SUBMENU",
                                    path: "/settings/curriculums"
                                },
                                {
                                    name: "Education Levels",
                                    translate: "sidebar.nav.SUBMENU",
                                    path: "/settings/education-levels"
                                },
                                {
                                    name: "Grades",
                                    translate: "sidebar.nav.SUBMENU",
                                    path: "/settings/grades"
                                },
                                {
                                    name: "Subjects",
                                    translate: "sidebar.nav.SUBMENU",
                                    path: "/settings/subjects"
                                },
                                {
                                    name: "Topics",
                                    translate: "sidebar.nav.SUBMENU",
                                    path: "/settings/topics"
                                },
                                {
                                    name: "Avatars",
                                    translate: "sidebar.nav.SUBMENU",
                                    path: "/settings/avatars"
                                },
                            ]
                        },
                    )
                }

            }else{
                this.setState({ redirect: true })
            }
        
        }else{
            this.setState({ redirect: true })
        }

        this.state.Menu.push(
            {
              name: "DASHBOARD DATA",
              icon: "icon-settings",
              translate: "sidebar.nav.MENU",
              path: "/dashboard-management/v1-datas",
            }
        )
          

        this.state.Menu.push(
            {
                name: "LOGOUT",
                icon: "icon-logout",
                translate: "sidebar.nav.MENU",
                path: "/logout",
            }
        )
    }



    closeSidebar = () => {
        this.props.actions.toggleSetting('asideToggled');
    }

    /** prepare initial state of collapse menus. Doesnt allow same route names */
    buildCollapseList = () => {
        let collapse = {};
        this.state.Menu
            .filter(({heading}) => !heading)
            .forEach(({name, path, submenu}) => {
                collapse[name] = this.routeActive(submenu ? submenu.map(({path})=>path) : path)
            })
        this.setState({collapse});
    }

    navigator = route => {
        this.props.history.push(route);
    }

    routeActive(paths) {
        paths = Array.isArray(paths) ? paths : [paths];
        return paths.some(p => this.props.location.pathname.indexOf(p) > -1)
    }

    toggleItemCollapse(stateName) {
        for (let c in this.state.collapse) {
            if (this.state.collapse[c] === true && c !== stateName)
                this.setState({
                    collapse: {
                        [c]: false
                    }
                });
        }
        this.setState({
            collapse: {
                [stateName]: !this.state.collapse[stateName]
            }
        });
    }

    getSubRoutes = item => item.submenu.map(({path}) => path)

    /** map menu config to string to determine which element to render */
    itemType = item => {
        if (item.heading) return 'heading';
        if (!item.submenu) return 'menu';
        if (item.submenu) return 'submenu';
    }

    render() {
        return (
            <aside className='aside-container'>
                { /* START Sidebar (left) */ }
                <div className="aside-inner">
                    {this.state.redirect && <Redirect to="/logout" /> }
                    <nav data-sidebar-anyclick-close="" className="sidebar">
                        { /* START sidebar nav */ }
                        <ul className="sidebar-nav">
                            { /* START user info */ }
                            <li className="has-user-block">
                                <SidebarUserBlock/>
                            </li>
                            { /* END user info */ }

                            { /* Iterates over all sidebar items */ }
                            {
                                this.state.Menu.map((item, i) => {
                                    // heading
                                    if(this.itemType(item) === 'heading')
                                        return (
                                            <SidebarItemHeader item={item} key={i} />
                                        )
                                    else {
                                        if(this.itemType(item) === 'menu')
                                            return (
                                                <SidebarItem isActive={this.routeActive(item.path)} item={item} key={i} />
                                            )
                                        if(this.itemType(item) === 'submenu')
                                            return [
                                                <SidebarSubItem item={item} isOpen={this.state.collapse[item.name]} handler={ this.toggleItemCollapse.bind(this, item.name) } isActive={this.routeActive(this.getSubRoutes(item))} key={i}>
                                                    <SidebarSubHeader item={item} key={i}/>
                                                    {
                                                        item.submenu.map((subitem, i) =>
                                                            <SidebarItem key={i} item={subitem} isActive={this.routeActive(subitem.path)} />
                                                        )
                                                    }
                                                </SidebarSubItem>
                                            ]
                                    }
                                    return null; // unrecognized item
                                })
                            }
                        </ul>
                        { /* END sidebar nav */ }
                    </nav>
                </div>
                { /* END Sidebar (left) */ }
            </aside>
        );
    }
}

Sidebar.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object
};

const mapStateToProps = state => ({ settings: state.settings })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withNamespaces('translations')(withRouter(Sidebar)));
