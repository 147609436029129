
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {reactLocalStorage} from 'reactjs-localstorage';

let user_session = reactLocalStorage.getObject('user_session');

//console.log(user_session);

const ProtectedRoute = ({component: Component, ...rest}) => {
    return (
        <Route
            {...rest}
            render={props => {
                if(user_session && user_session.user) {
                    //console.log()
                    return (
                        <Component {...props} />
                    )
          
                }else{
                    return ( 
                        <Redirect 
                            to={{
                                pathname: "/",
                                state: {
                                    from: props.location
                                }
                             }}
                        />
                    );  
                }
            }}
        />
    );
}


export default ProtectedRoute;
  